
import { defineComponent, ref } from "vue";
import Shedule from "@/components/website/tabs/Shedule.vue";
import Rate from "@/components/website/tabs/Rate.vue";

export default defineComponent({
  name: "Section2",
  components: {
    Shedule,
    Rate,
  },

  setup() {
    const active = ref("sheludes");

    return {
      active,
    };
  },
});
