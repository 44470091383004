<template>
  <div class="container-section-3">
    <div class="container-next">
      <h6>tu próximo envío junto a nosotros</h6>
      <div class="row gx-5">
        <div class="col-4">
          <div class="container-ship"></div>
        </div>
        <div class="col-4">
          <div class="container-ship"></div>
        </div>
        <div class="col-4">
          <div class="container-ship"></div>
        </div>
      </div>
      <div class="row gx-5">
        <div class="col-4">
          <div class="container-info">
            <div class="container-numbers">
              <div class="before">antes</div>
              <div class="before-number">0000</div>
            </div>
            <div class="now-number">0000</div>
            <div class="container-button">
              <a href="#" class="btn-see-more">VER MÁS</a>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="container-info">
            <div class="container-numbers">
              <div class="before">antes</div>
              <div class="before-number">0000</div>
            </div>
            <div class="now-number">0000</div>
            <div class="container-button">
              <a href="#" class="btn-see-more">VER MÁS</a>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="container-info">
            <div class="container-numbers">
              <div class="before">antes</div>
              <div class="before-number">0000</div>
            </div>
            <div class="now-number">0000</div>
            <div class="container-button">
              <a href="#" class="btn-see-more">VER MÁS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section3",
};
</script>
