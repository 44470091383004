
import { ref } from "vue";
export default {
  name: "RateResponse",
  props: {
    quotation: {
      type: Array,
      default: undefined,
    },
  },
  setup(props) {
    const content = ref(false);
    const totalRate = () => {
      let rate = 0;
      props.quotation.forEach(function (value, index) {
        rate += Number(value.precio_unidad);
      });
      return rate;
    };
    const getPer = (per) => {
      switch (per){
        case '2':
          return '20\''
        case '4':
          return '40\''
        case 'C':
          return 'Container'
        case 'F':
          return 'Weight/Measurement'
        case 'G':
          return 'Ton'
        case 'M':
          return 'CBM'
        case 'P':
          return 'Unit'
        case 'T':
          return 'TEU'
        case 'LM':
          return 'Length meter'
        case 'VSGRT':
          return 'Vessel gross ton'
        case 'VSLEN':
          return 'Vessel length'
        case 'VSDRAFT':
          return 'Vessel draft'
        case 'VSNRT':
          return 'Vessel net ton'
        case 'VSCBM':
          return 'Vessel cubic meters'
        case 'VSLB':
          return 'Vessel Summer Draft Pre-Calculation'
        case 'VSVOL':
          return 'Vessel Volume'
        case 'VSBEAM':
          return 'Vessel Beam'
        case 'VSLRDGT':
          return 'Vessel per reduced gross tonnage'
        case 'TDW':
          return 'Total Deadweight'
        case 'LOSTSLOT':
          return 'Lost slot'
        case 'WM':
          return 'Weight/Measurement'
        case 'WMI':
          return 'Weight/Measurement Imperial'
        case 'KM':
          return 'Kilometers'
        case 'MI':
          return 'Miles'
        case 'DIFKM':
          return 'Kilometers'
        case 'DIFMI':
          return 'Miles'
        case 'CODOR':
          return 'COD Operational Reason'
        case 'CODCR':
          return 'COD Customer Request'
        case 'Q':
          return 'Quantity'
        case '%FB':
          return 'COMISION COMERCIAL'
        case '%':
          return '%'
        case '10DC':
          return '10\' Dry Container'
        case 'L':
          return 'Lumpsum'
        default:
          return per
      }
    };

    return {
      content,
      totalRate,
      getPer,
    };
  },
};
