<template>
  <div class="d-flex">
    <div class="header-register">
      <div class="row container-form">
        <div class="col-md-4">
          <label class="form-label">Nombre</label>
          <input
            v-model="booking"
            type="text"
            autocomplete="off"
            class="form-control form-control-website"
          />
        </div>
        <div class="col-md-8">
          <label class="form-label">Apellidos</label>
          <input
            v-model="booking"
            type="text"
            autocomplete="off"
            class="form-control form-control-website"
          />
        </div>
        <div class="col-md-4 mt-5">
          <label class="form-label">Dirección</label>
          <input
            v-model="booking"
            type="text"
            autocomplete="off"
            class="form-control form-control-website"
          />
        </div>
        <div class="col-md-4 mt-5">
          <label class="form-label">Número de teléfono</label>
          <input
            v-model="booking"
            type="text"
            autocomplete="off"
            class="form-control form-control-website"
          />
        </div>
        <div class="col-md-4 mt-5">
          <label class="form-label">Correo electrónico </label>
          <input
            v-model="booking"
            type="text"
            autocomplete="off"
            class="form-control form-control-website"
          />
        </div>
        <div class="col-12 d-flex justify-content-center">
          <div class="container-button">
            <a href="#" class="btn-see-more">REGISTRAR</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Register",
  setup() {
    const booking = ref("");
    const container = ref("");
    const bl = ref("");

    return {
      booking,
      container,
      bl,
    };
  },
});
</script>
