import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-section-2" }
const _hoisted_2 = { class: "container-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Shedule = _resolveComponent("Shedule")!
  const _component_Rate = _resolveComponent("Rate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["btn-section-2", [_ctx.active === 'sheludes' ? 'active' : '']]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.active = 'sheludes'))
      }, " BUSCAR SCHEDULES ", 2),
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["btn-section-2", [_ctx.active === 'rate' ? 'active' : '']]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.active = 'rate'))
      }, " CONSULTA DE TARIFA ", 2)
    ]),
    (_ctx.active === 'sheludes')
      ? (_openBlock(), _createBlock(_component_Shedule, { key: 0 }))
      : (_ctx.active === 'rate')
        ? (_openBlock(), _createBlock(_component_Rate, { key: 1 }))
        : _createCommentVNode("", true)
  ]))
}