import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: $setup.element_id,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.element_id) = $event)),
    class: "form-control form-control-website",
    multiple: $props.multiselect,
    "collapse-tags": $props.multiselect,
    clearable: "",
    placeholder: "Seleccione un puerto",
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    "remote-method": $setup.selectPort,
    loading: $props.pol ? $setup.inputPol.loading : $setup.inputPod.loading,
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:port_id', $event)))
  }, {
    default: _withCtx(() => [
      ($props.pol)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inputPol.options, (port) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: `${port.tmh_pol}`,
                label: `${port.polname}, ${port.pol_countryname} (${port.tmh_pol})`,
                value: port.tmh_pol
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(`${port.polname}, ${port.pol_countryname} (${port.tmh_pol})`), 1)
                ]),
                _: 2
              }, 1032, ["label", "value"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inputPod.options, (port) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: `${port.tmh_pod}`,
                label: `${port.podname}, ${port.podname} (${port.tmh_pod})`,
                value: port.tmh_pod
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(`${port.podname}, ${port.podname} (${port.tmh_pod})`), 1)
                ]),
                _: 2
              }, 1032, ["label", "value"]))
            }), 128))
          ]))
    ]),
    _: 1
  }, 8, ["modelValue", "multiple", "collapse-tags", "remote-method", "loading"]))
}