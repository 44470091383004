
import moment from "moment";
import { defineComponent, ref, watch } from "vue";
import PortSelect from "@/components/website/selects/PortSelect.vue";
import ApiService from "@/core/services/ApiService";
moment.locale("es");

export default defineComponent({
  name: "Shedule",
  components: { PortSelect },
  setup() {
    const showResult = ref(false);
    const cardRef = ref<[] | any>([]);
    const rheta = ref(moment().format("YYYY-MM-DD"));
    const form = ref({
      rhhafen_pol: "",
      rhhafen_pod: "",
      rheta: moment(rheta.value).format("YYYYMMDD"),
      week: 2,
      direct_routing: "0",
    });
    const weeks = ref([
      {
        id: 1,
        label: "1 Semana",
      },
      {
        id: 2,
        label: "2 Semanas",
      },
      {
        id: 3,
        label: "3 Semanas",
      },
      {
        id: 4,
        label: "4 Semanas",
      },
    ]);
    const details = ref<[] | any>([]);
    const voyagesGen = ref<[] | any>([]);
    const voyages = ref<[] | any>([]);

    watch(
      () => rheta.value,
      (first) => {
        if (first) {
          form.value.rheta = moment(rheta.value).format("YYYYMMDD");
        }
      }
    );

    const formatDate = (date) => {
      return moment(date).format("ll");
    };

    const showDetails = (value) => {
      const index = cardRef.value.indexOf(value);
      if (index === -1) {
        cardRef.value.push(value);
      } else {
        cardRef.value.splice(index, 1);
      }
    };

    const arrivalDate = (voyage) => {
      const last = voyage.schedules[voyage.schedules.length - 1];
      const arrival = moment(last.ETA_POD).format("ll");
      return arrival;
    };

    const calculateDays = (voyage) => {
      if (form.value.direct_routing === "0") {
        const departure = moment(voyage.header.rheta).format("YYYY-MM-DD");
        const last = voyage.schedules[voyage.schedules.length - 1];
        const arrival = moment(last.ETA_POD).format("YYYY-MM-DD");
        const date1 = moment(departure);
        const date2 = moment(arrival);
        const duration = moment.duration(date2.diff(date1)).asDays();
        return Math.round(duration) <= 0 ? 1 : Math.round(duration);
      }
      const departure = moment(voyage.ETA_POL).format("YYYY-MM-DD");
      const arrival = moment(voyage.ETA_POD).format("YYYY-MM-DD");
      const date1 = moment(departure);
      const date2 = moment(arrival);
      const duration = moment.duration(date2.diff(date1)).asDays();
      return Math.round(duration) <= 0 ? 1 : Math.round(duration);
    };

    const onFind = () => {
      voyagesGen.value = [];
      voyages.value = [];
      details.value = [];
      ApiService.query("/api/lima/schedules", {
        params: {
          rhhafen_pol: form.value.rhhafen_pol,
          rhhafen_pod: form.value.rhhafen_pod,
          rheta: form.value.rheta,
          week: form.value.week,
          direct_routing: form.value.direct_routing,
        },
        /*params: {
          rhhafen_pol: "CNTAO",
          rhhafen_pod: "GQSSG",
          rheta: "20220204",
          week: 4,
          direct_routing: 0,
        },*/
      }).then(({ data }) => {
        if (form.value.direct_routing === "0") {
          const voyages_keys = data.voyages.map((x) => x.rhrmanr);

          voyages_keys.forEach((key, index1) => {
            const schedules = data.schedules.filter(
              (schedule) => schedule.rh_rmanr === key
            );

            const schedules_group = schedules.reduce((r, a) => {
              r[a.rh_rmanr] = r[a.rh_rmanr] || [];
              r[a.rh_rmanr].push(a);
              return r;
            }, Object.create(null));

            console.log('schedules_group', schedules_group)

            const schedules_keys = schedules_group[`${key}`].map(
              (x) => x.route_det_sno
            );
            const schedules_filter_keys = schedules_keys.filter(
              (key, index) => {
                return schedules_keys.indexOf(key) === index;
              }
            );

            const orders = schedules_group[`${key}`].reduce((r, a) => {
              r[a.route_det_sno] = r[a.route_det_sno] || [];
              r[a.route_det_sno].push(a);
              return r;
            }, Object.create(null));

            console.log('orders', orders)

            const header = data.voyages[index1];

            for (let i = 0; i < schedules_group[`${key}`].length; i++) {
              const details = [] as any;
              details.push(schedules_group[`${key}`][i]);
              console.log('0', schedules_group[`${key}`][i])
              for (let j = 0; j < schedules_group[`${key}`].length; j++) {
                if (schedules_group[`${key}`][j].rh_rmanr === header.rhrmanr) {
                  const exist = details.filter(
                    (x) =>
                      x.route_det_sno ===
                      schedules_group[`${key}`][j].route_det_sno
                  );
                  if (!exist.length) {
                    details.push(schedules_group[`${key}`][j]);
                  }
                }
              }
              console.log('details', details)
              voyages.value.push({
                header: header,
                schedules: details,
              });
            }
          });
        } else {
          voyages.value = data;
        }
        showResult.value = true;
      });
    };

    return {
      form,
      rheta,
      weeks,
      voyagesGen,
      voyages,
      details,
      cardRef,
      arrivalDate,
      showResult,
      calculateDays,
      showDetails,
      onFind,
      formatDate,
    };
  },
});
