
import { defineComponent, ref } from "vue";
import Register from "@/components/website/Register.vue";
import Section2 from "@/components/website/Section2.vue";
import Section3 from "@/components/website/Section3.vue";
// RateResponse from "@/components/website/RateResponse.vue";

export default defineComponent({
  name: "Website",
  components: {
    Register,
    Section2,
    Section3,
    //RateResponse,
  },

  setup() {
    const register = ref(false);

    return {
      register,
    };
  },
});
