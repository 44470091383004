
import { ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";

export default {
  name: "PortSelect",
  props: {
    port_id: {
      type: String,
      default: undefined,
    },
    pol: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:port_id"],

  setup(props) {
    const element_id = ref(undefined);
    const inputPol = ref({
      loading: false,
      options: [],
      list: [],
    });
    const inputPod = ref({
      loading: false,
      options: [],
      list: [],
    });

    const selectPort = (query) => {
      if (query !== "") {
        setTimeout(() => {
          if (props.pol) {
            inputPol.value.loading = true;
            ApiService.query("/api/lima/portLoading", {
              params: { rhhafen: query, rheta: "20211013" },
            }).then(({ data }) => {
              inputPol.value.loading = false;
              inputPol.value.list = data;
              inputPol.value.options = data;
            });
          } else {
            inputPod.value.loading = true;
            ApiService.query("/api/lima/portDischarge", {
              params: { rhhafen: query, rheta: "20211013" },
            }).then(({ data }) => {
              inputPod.value.loading = false;
              inputPod.value.list = data;
              inputPod.value.options = data;
            });
          }
        }, 1000);
      } else {
        if (props.pol) {
          inputPol.value.options = [];
        } else {
          inputPod.value.options = [];
        }
      }
    };

    watch(
      () => props.port_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    return {
      element_id,
      inputPol,
      inputPod,
      selectPort,
    };
  },
};
