
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import PortSelect from "@/components/website/selects/PortSelect.vue";
import RateResponse from "@/components/website/RateResponse.vue";

export default defineComponent({
  name: "Rate",
  components: { PortSelect, RateResponse },
  setup() {
    const deliver = ref("");
    const pol = ref("");
    const pod = ref("");
    const reception = ref("");

    const name = ref("");
    const email = ref("");
    const cif = ref("");
    const mobile = ref("");
    const addres = ref("");

    const kind_containers = ref<[] | any>([]);
    const kind_containers_selected = ref<[] | any>([]);
    const quotations = ref<[] | any>([]);

    const getKindContainers = () => {
      ApiService.get("/api/kind_container").then(({ data }) => {
        data.forEach(function (value, index){
          if(value.web_description !== '' && value.web_description !== null){
            kind_containers.value.push(value);
          }
        });
      });
    };

    const updateKindContainersSelected = (item) => {
      let addFlag = true;
      kind_containers_selected.value.forEach(function (value, index) {
        if (value === item.code && addFlag) {
          kind_containers_selected.value.splice(index, 1);
          addFlag = false;
        }
      });
      if (addFlag) {
        kind_containers_selected.value.push(item.code);
      }
    };

    const getQuotations = () => {
      ApiService.query("/api/lima/quotations", {
        params: {
          qt2polcde: pol.value,
          qt2podcde: pod.value,
          qt2kopcde: kind_containers_selected.value,
        },
      }).then(({ data }) => {
        quotations.value = data;
      });
    };

    onMounted(() => {
      getKindContainers();
    });

    return {
      kind_containers,
      kind_containers_selected,
      quotations,
      getKindContainers,
      updateKindContainersSelected,
      getQuotations,

      deliver,
      pol,
      pod,
      reception,

      name,
      email,
      cif,
      mobile,
      addres,
    };
  },
});
